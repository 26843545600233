.LaunchPad {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background-color: #232030; 
  }
  
  .svg-container {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }
  
  .moon-svg {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
    z-index: 1; 
  }
  
  .rocketship-svg {
    position: absolute;
    bottom: 6%;  
    left: 50%;
    transform: translateX(-50%);
    width: 28%;
    height: auto;
    z-index: 2; 
  }
  
  .top-students {
    position: absolute;
    bottom: 63%; 
    left: 69%; 
    transform: translateX(-50%);
  }

  .middle-students {
    position: absolute;
    bottom: 40%;
    right: 61%;
  }

  .bottom-students {
    position: absolute;
    bottom: 2%;
    left: 58%;
  }

  .help-button{
    z-index: 3;
  }