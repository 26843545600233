.message-bubble {
    display: flex;
    flex-direction: column; 
    max-width: 70%;
    padding: 10px 15px;
    margin: 10px 0;
    border-radius: 15px;
    font-size: 14px;
    line-height: 1.5;
    word-wrap: break-word;
  }
  
  .header-container {
    display: flex;
    align-items: center; 
    margin-bottom: 5px;  
    width: 100%;          
    justify-content: space-between; 
  }
  
  .buttonsContainer{
    gap: 2px;
    align-items: center; /* Center buttons vertically */
    flex-direction: row;
    display: flex;
    justify-content: flex-end; /* Push buttons to the right */
  }

  .user-message {
    align-self: flex-end;
    background-color: #ECE6F0;
    color: black;
    border-bottom-right-radius: 5px;
    text-align: left;
  }
  
  .assistant-message {
    align-self: flex-start;
    background-color: #e5e5e5;
    color: #333;
    border-bottom-left-radius: 5px;
    text-align: left;
  }
  
  .message-content {
    white-space: pre-wrap; 
    font-family: Roboto;
  }
  