.chat-box {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-grow: 1;
    width: 100%;
    max-width: 99%; /* no scrollbar */
    margin: 0 auto;
    margin-bottom: 8px;
    background-color: #fff;
    border-radius: 24px;
    padding: 4px;
}

.chat-input {
    padding-top: 0;
    padding-bottom: 0;
    flex-grow: 1;
    background-color: #fff;
    border-radius: 24px;
    box-shadow: none;
    resize: none;
    overflow: hidden; /* Prevents scrollbar */
    white-space: pre-wrap; /* Allows wrapping */
}
  
.send-button {
    padding: 0;
    border-radius: 50%; 
}
  
.send-button:hover {
    background-color: rgba(25, 118, 210, 0.1); 
}
  