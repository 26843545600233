.individual-student {
    margin: 6px;
  }
  
  button {
    color: white;
    padding: 4px 4px;
    border: none;
    cursor: pointer;
  }
  
