/* ReportBubbleComponent.css */
.report-bubble {
  background-color: #f1f1f1;
  padding: 20px;
  border-radius: 15px;
  max-width: 50%;         /* You can adjust this based on how wide you want the bubble */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  line-height: 1.6;
  word-wrap: break-word;  /* Prevents words from overflowing */
  white-space: pre-wrap;  /* Keeps the formatting of the report */
  overflow: auto;
}
