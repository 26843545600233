/* Modal styling */
.MuiPaper-root {
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-height: 90vh;
}
  
.IEP-modal-header {
    display: flex;
    justify-content: space-between;
    padding: 5px;
    background-color: #ECE6F0;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}
  
/* Title Styling */
.IEP-modal-header h3 {
    color: black;
    font-size: 21px;
    font-weight: 600;
    margin: 0;
    text-align: left;
    flex-grow: 1;
    padding-left: 10px;
    padding-top: 10px;
}
  
.close-button {
    color: white;
    font-size: 28px;
    padding-left: 20px;
}
  
.iep-information-container{
    color: #6A5ACD;
}

.MuiFormControl-root {
    min-width: 200px;
}
  
.MuiInputLabel-root {
    color: white;
}
  
.MuiSelect-root {
    border-radius: 12px;
    background-color: #fff;
    padding: 8px 12px;
    border: 1px solid #6A5ACD;
    width: 100%;
}
  
.MuiSelect-root:hover {
    background-color: #f3f4f6;
}
  
.MuiMenuItem-root {
    font-size: 16px;
}

.modal-content:hover {
    scrollbar-color: #999 transparent; /* Light gray scrollbar on hover */
}

.modal-content::-webkit-scrollbar {
    width: 8px;
}

.modal-content::-webkit-scrollbar-thumb {
    background-color: transparent; /* Hide scrollbar initially */
    border-radius: 10px;
}

.modal-content:hover::-webkit-scrollbar-thumb {
    background-color: #999; /* Light gray scrollbar when hovered */
}
