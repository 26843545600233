.main-rectangle {
    position: absolute; 
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 290px;
    max-height: 200px;
    padding: 12px;
    gap: 6px;
    border-radius: 20px;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    overflow: hidden;  
}

.topx-header {
    font-family: var(--Title-Large-Font, Roboto);
    margin: 0;
    text-align: left;  
    width: 100%;
    background: #FFF;  
    position: sticky;
    top: 0;
    z-index: 10;  
    padding-bottom: 5px;
    border-bottom: 1px solid #ddd; 
}

.students-list {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
    overflow-y: auto;  
    max-height: 160px; 
}

.students-list::-webkit-scrollbar {
    display: none;
}

.students-list {
    scrollbar-width: none;
    -ms-overflow-style: none; 
}

.students-list {
    max-height: 60vh; /* Adjust as needed */
    overflow: auto; /* Scroll only when necessary */
    scrollbar-width: thin; /* Firefox: Thin scrollbar */
    scrollbar-color: transparent transparent; /* Hides scrollbar */
}

.students-list:hover {
    scrollbar-color: #999 transparent; /* Light gray scrollbar on hover */
}

.students-list::-webkit-scrollbar {
    width: 8px;
}

.students-list::-webkit-scrollbar-thumb {
    background-color: transparent; /* Hide scrollbar initially */
    border-radius: 10px;
}

.students-list:hover::-webkit-scrollbar-thumb {
    background-color: #999; /* Light gray scrollbar when hovered */
}
