.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.modal-body {
    display: flex;
    flex-direction: column; 
    align-items: center; 
    width: 98%;
    max-width: 100%;
    gap: 12px;
    padding: 12px;
    /* overflow-y: hidden;  */
    overflow-x: hidden; 
}

.rectangle {
    background-color: #FFFFFF;
    padding: 14px;
    border-radius: 10px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}

.rectangle-text{
    text-align: left;
    margin: 8px;
}

.left-container {
    flex: 0.8;
    display: flex;
    flex-direction: column;
    gap: 16px;
    overflow: hidden;
}

.right-container {
    flex: 0.2;
    display: flex;
    justify-content: center;
    align-items: center;
}

.top-container {
    display: flex; 
    flex-direction: row; 
    gap: 5%;
}

.bottom-container {
    flex: 1; 
    display: flex; 
    flex-direction: column;
    padding-top: 8px;
    gap: 16px;
}

.best-and-worst-container {
    flex: 1;
    display: flex;
    flex-direction: row;
    gap: 16px;
}

.modal-body {
    overflow: auto; /* Enables scrolling when needed */
    max-height: 70vh; /* Adjust based on your layout */
    scrollbar-width: thin; /* For Firefox */
    scrollbar-color: transparent transparent; /* Hides scrollbar */
}

.modal-body:hover {
    scrollbar-color: #999 transparent; /* Makes scrollbar visible on hover */
}

.modal-body::-webkit-scrollbar {
    width: 8px;
}

.modal-body::-webkit-scrollbar-thumb {
    background-color: transparent; /* Invisible scrollbar */
    border-radius: 10px;
}

.modal-body:hover::-webkit-scrollbar-thumb {
    background-color: #999; /* Light gray scrollbar when hovered */
}
