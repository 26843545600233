.pie-chart-container {
    text-align: center;
    background-color: #FFFFFF;
    border-radius: 12px;      
    padding: 20px;        
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}
  
.pie-chart-title {
    margin-bottom: 10px;
    color: black;
    font-weight: bold;
}