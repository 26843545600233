:root {
    --background-color: #232030;
    --sys-light-surface-container-high: #ECE6F0;
}

body {
    font-family: 'Roboto';
    background-color: var(--background-color);
}

.mainPageLayout {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    padding-bottom: 15%;
}

.mainPageTextLayout {
    font-family: Roboto;
    flex-direction: column;
    z-index: 1;
}

.mainPageTitleFont {
    margin-bottom: 14px;
    align-self: stretch;
    color: var(--M3-white, #FFF);
    text-align: center;
    font-family: var(--Display-Large-Font, Roboto);
    font-size: var(--Display-Large-Size, 57px);
    font-style: normal;
    font-weight: 700;
    line-height: 0;
    letter-spacing: var(--Display-Large-Tracking, -0.25px);
}

.mainPageParagraphFont {
    margin-bottom: 14px;
    align-self: stretch;
    color: var(--Color, #CDCDCD);
    text-align: center;
    font-family: Roboto;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 128.571% */
    max-width: 650px;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
    z-index: 1000; /* Below new content but above the rest */
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: 23px;
    flex-shrink: 0;
}

.conversation {
    padding-bottom: 5%;
}