/* Modal styling */
.MuiPaper-root {
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-height: 90vh;
}

.chat-modal-header {
    display: flex;
    justify-content: space-between;
    padding: 5px;
    background-color: #ECE6F0;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

/* Title Styling */
.chat-modal-header h3 {
    color: black;
    font-size: 21px;
    font-weight: 600;
    margin: 0;
    text-align: left;
    flex-grow: 1;
    padding-left: 10px;
    padding-top: 10px;
}

.close-button {
    color: white;
    font-size: 28px;
    padding-left: 20px;
}

.modal-content {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-grow: 1;
    width: 100%;
    max-width: 89%; /* no scrollbar */
    margin: 0 auto;
    margin-bottom: 8px;
    background-color: #fff;
    border-radius: 24px;
    padding: 4px;       
    overflow: auto; /* Allow scrolling when content overflows */
    scrollbar-width: thin; /* Firefox: Thin scrollbar */
    scrollbar-color: transparent transparent; /* Initially hidden */
}

.modal-content:hover {
    scrollbar-color: #999 transparent; /* Light gray scrollbar on hover */
}

.modal-content::-webkit-scrollbar {
    width: 8px;
}

.modal-content::-webkit-scrollbar-thumb {
    background-color: transparent; /* Hide scrollbar initially */
    border-radius: 10px;
}

.modal-content:hover::-webkit-scrollbar-thumb {
    background-color: #999; /* Light gray scrollbar when hovered */
}

.loading-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.loading-container p {
    margin-top: 10px;
}