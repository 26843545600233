/* Modal styling */
.MuiPaper-root {
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-height: 90vh;
}

.chat-modal-header {
  display: flex;
  justify-content: space-between;
  padding: 5px;
  background-color: #ECE6F0;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

/* Title Styling */
.chat-modal-header h3 {
  color: black;
  font-size: 21px;
  font-weight: 600;
  margin: 0;
  text-align: left;
  flex-grow: 1;
  padding-left: 10px;
  padding-top: 10px;
}

.close-button {
  color: white;
  font-size: 28px;
  padding-left: 20px;
}

.MuiFormControl-root {
  min-width: 200px;
}

.MuiInputLabel-root {
  color: white;
}

.MuiSelect-root {
  border-radius: 12px;
  background-color: #fff;
  padding: 8px 12px;
  border: 1px solid #6A5ACD;
  width: 100%;
}

.MuiSelect-root:hover {
  background-color: #f3f4f6;
}

.MuiMenuItem-root {
  font-size: 16px;
}

.input-bubble-container {
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.input-area {
  background-color: #ECE6F0;
  border-radius: 20px;
  padding: 12px 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  border: none;
  font-size: 16px;
}

.input-area:focus {
  outline: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}